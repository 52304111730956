import Piece1 from '@images/serviceHero/wordpress/wordpress-piece-1.svg'
import Piece2 from '@images/serviceHero/wordpress/wordpress-piece-2.svg'
import Piece3 from '@images/serviceHero/wordpress/wordpress-piece-3.svg'
import Piece4 from '@images/serviceHero/wordpress/wordpress-piece-4.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { useEffect, useRef } from 'react'

const WordpressIcon = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece-1'),
            {
                top: '5%',
                left: '30%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 269px)',
                left: '25%',

                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-2'),
            {
                top: '25%',
                left: '5%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 181px)',
                left: 'calc(25% - 22px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-3'),
            {
                top: '20%',
                left: '75%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 184px)',
                left: 'calc(25% + 226px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-4'),
            {
                top: '40%',
                left: '60%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 98px)',
                left: 'calc(25% + 103px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])
    return (
        <div
            ref={ref}
            className="hidden xl:block relative md:col-[1_/_2] md:row-[2_/_4]"
        >
            <div className="absolute top-[10%] wrap-pice"></div>
            <div className="piece-1 absolute z-[2] w-[calc(4231px*0.06)]">
                <img alt="wordpress-piece" src={Piece1} />
            </div>
            <div className="piece-2 absolute z-[2] w-[calc(1579px*0.06)]">
                <img alt="wordpress-piece" src={Piece2} />
            </div>
            <div className="piece-3 absolute z-[2] w-[calc(1394px*0.06)]">
                <img alt="wordpress-piece" src={Piece3} />
            </div>
            <div className="piece-4 absolute z-[2] w-[calc(1722px*0.06)]">
                <img alt="wordpress-piece" src={Piece4} />
            </div>
        </div>
    )
}

export default WordpressIcon
