import React from "react"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import Layout from "../../components/layout"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import ServiceCTA from "./../../components/ServiceLanding/CTA/ServiceCTA"
import HeroWordpress from "./../../components/ServiceSinglePage/Hero/Wordpress/HeroWordpress"
import { SEO } from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"
import localisationData from '../../data/localisation.json'
import ThreeColumnContent from "../../components/ThreeColumnContent"

const heroTextTitle = "Professional Web Development Services"
const heroTextSmallText =
    "Saigon Digital's web developers focus on client needs, creating impactful website solutions and guiding brands through the evolving digital landscape."
const ctaTitle = "Your Trusted Partner for Full Service Web Development"
const heroData = [
    "For years, we've been at the forefront of professional web development services, offering cutting-edge solutions that leverage the latest tech advancements. As a leading web development services company, our expert developers can handle a diverse range of projects, from customer-facing apps and internal portals to e-commerce stores with augmented reality features.",
    "We pride ourselves on our transparent and efficient development process, ensuring your project meets the highest standards of quality. Our team combines years of experience with a deep understanding of modern programming languages and frameworks.",
    "Whether you're seeking open-source solutions like PHP and MySQL or Microsoft technologies such as ASP, .NET, and SQL Server, our full-service web development expertise has you covered. We also work with a variety of content management systems, including WordPress, SugarCRM, and more.",
    "Our skilled developers can tailor your project to your exact specifications, creating a custom web development solution that exceeds your expectations.",
    "Choose Saigon Digital for reliable, innovative, and efficient web development services. Our digital agency web development team is dedicated to providing end-to-end web solutions that drive results."
]
const iconBlockTitle = "End-to-End Web Solutions for Growth"
const icons = [
    {
        name: "check",
        title: "Frontend Development",
        text: "We specialise in crafting engaging user interfaces using cutting-edge frameworks like React, Vue, and Angular."
    },
    {
        name: "performance",
        title: "Backend Development",
        text: "Our skilled developers build robust, scalable backends using server-side technologies such as Python, Ruby on Rails, and Node.js."
    },
    {
        name: "chart",
        title: "Full Stack Development",
        text: "From the front end to the back end, we deliver comprehensive web development solutions tailored to your specific needs."
    },
    {
        name: "content",
        title: "Low-Code Development",
        text: "Save time and money with our low-code and no-code development services. We leverage platforms like Webflow and Mendix to create efficient web solutions."
    },
    {
        name: "bolt",
        title: "Cloud Development",
        text: "We deploy your applications securely and efficiently on cloud platforms like Google Cloud, AWS, and Azure."
    },
    {
        name: "content",
        title: "Website Security Audits",
        text: "Protecting your data and enhancing your SEO, our web development services include comprehensive security audits to ensure your website is safe and optimised."
    }
]
const cta1 = {
    title: "Get a Bespoke Web Solution",
    description:
        "We continuously test, update, and enhance our development processes. Our developers are dedicated to ongoing learning, ensuring your project takes advantage of the latest innovations and technologies. This approach enables us to offer a seamless experience, from the initial brainstorming phase to the implementation and maintenance of your solution."
}
const cta2 = {
    title: "Experience premier web development that drives results and elevates your brand.",
    ctaText: "Book a call"
}

const Wordpress = ({ location }) => {
    const context = {
        pageName: "Service | Professional Web Development Services",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroWordpress
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="wordpress"
                    src="../../components/ServiceSinglePage/images/wordpress-text-image.svg"
                />
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} ctaText={cta2.ctaText} />
            </div>
            <ThreeColumnContent localisationData={localisationData.webdev}/>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.webDevelopmentFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default Wordpress
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: faqs.webDevelopmentFaqs.map(faq => ({
                        "@type": "Question",
                        name: faq?.question,
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: faq?.answer[0]
                        }
                    }))
                })}
            </script>
        </Helmet>
        <SEO
            title={"Professional Web Development Services | Saigon Digital"}
            description={
                "Saigon Digital can build pretty much anything with WordPress, be it a custom integration with your favourite third-party service or API"
            }
        />
    </>
)
